@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.swal2-checkbox input {
    -webkit-appearance: checkbox !important;
}

.no-before-icon::before {
    display: none !important;
}

html, body {
    min-width: 768px;
    overflow-x: hidden;
    overscroll-behavior-y: contain;
}

input {
    border-radius: 0;
    -webkit-appearance: none;
}

input[type="search"] {
    -webkit-appearance: none;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

::selection {
    background: #3b97d3;
    color: white;
}

.swal2-popup * {
    user-select: none;
}

li[class^='PrivateBreadcrumbCollapsed-root'], li[class*=' PrivateBreadcrumbCollapsed-root'] {
    background-color: white !important;
    border: 1px solid #cfcfcf !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background: #3b97d3 !important;
    font-family: "Roboto", sans-serif !important;
    border: none !important;
    border-radius: 5px !important;
    outline: 0 !important;
    box-shadow: none !important;
    transition: background 0.3s !important;
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
    background: #2884bf !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    width: 5px;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: #3b97d3;
    width: 5px;
    border-radius: 3px;
}

.MuiCircularProgress-colorPrimary {
    color: #3b97d3 !important;
}

.DayPicker-Day--today {
    color: #3b97d3 !important;
}
